import { Key } from 'react-aria-components';
import { getCustomRolesQuery } from '@gonfalon/rest-api';
import { Menu, MenuItem, Popover, SubmenuTrigger, Text } from '@launchpad-ui/components';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RoleValue } from './schema';

type OrganizationRoleMenuProps = { isSubmenu?: boolean; onChange: (role: RoleValue) => void };

export function OrganizationRoleMenu({ isSubmenu = false, onChange }: OrganizationRoleMenuProps) {
  const { data: organizationRolesRes } = useSuspenseQuery({
    ...getCustomRolesQuery({
      query: {
        filter: { resourceCategory: ['organization'] },
        limit: 20, // a large enough limit to ensure we get all org roles
      },
    }),
  });

  const organizationRoles = organizationRolesRes.items ?? [];

  const menuItems: RoleValue[] = organizationRoles.map((r) => ({
    type: 'organization',
    id: r._id,
    name: r.name,
    description: r.description,
  }));

  const renderMenuItem = (item: RoleValue) => (
    <MenuItem>
      <Text slot="label">{item.name}</Text>
      <Text slot="description">{item.description}</Text>
    </MenuItem>
  );

  const handleAction = (id: Key) => {
    const item = menuItems.find((menuItem) => menuItem.id === id);
    if (!item) {
      return;
    }
    onChange(item);
  };

  if (isSubmenu) {
    return (
      <SubmenuTrigger>
        <MenuItem>
          <Text slot="label">Organization roles</Text>
          <Text slot="description">
            Take advantage of our new role-based access control to provide invitees access to your organization’s
            LaunchDarkly account.
          </Text>
        </MenuItem>
        <Popover>
          <Menu items={menuItems} onAction={handleAction}>
            {(item) => renderMenuItem(item)}
          </Menu>
        </Popover>
      </SubmenuTrigger>
    );
  }

  return (
    <Menu items={menuItems} onAction={handleAction} aria-label="Organization roles">
      {(item) => renderMenuItem(item)}
    </Menu>
  );
}
