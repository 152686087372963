import { uniq } from '@gonfalon/es6-utils';
import { Team } from '@gonfalon/members';
import { PostMembersRequestBody } from '@gonfalon/openapi';
import { CustomRole, RoleName } from '@gonfalon/permissions';
import { z } from 'zod';

const RoleSchema = z.object({
  type: z.enum(['organization', 'legacy']),
  id: z.union([z.string(), z.nativeEnum(RoleName)]),
  name: z.string(),
  description: z.string().optional(),
});

export const InviteMembersFormSchema = z.object({
  emails: z
    .string()
    .min(1, { message: 'At least one email is required' })
    .refine(
      (value) => {
        const emails = parseEmails(value);
        return emails.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
      },
      { message: 'Invalid email format' },
    ),
  role: RoleSchema,
  customRole: z.custom<CustomRole>().optional(),
  teams: z.array(z.custom<Team>()).optional(),
});

export type InviteMembersFormValues = z.infer<typeof InviteMembersFormSchema>;
export type RoleValue = z.infer<typeof RoleSchema>;

export function parseEmails(emails: string) {
  return uniq(
    emails
      .split(/[,\n]/) // Split on comma OR newline
      .map((email) => email.trim())
      .filter((email) => email.length > 0),
  );
}

function parseRole(role: RoleValue) {
  if (role.type === 'legacy') {
    if (role.id === RoleName.CUSTOM) {
      return RoleName.READER;
    }

    return role.id as RoleName;
  }

  // always returning NO_ACCESS for organization roles
  return RoleName.NO_ACCESS;
}

function parseCustomRoles(role: RoleValue, customRole?: CustomRole) {
  if (role.type === 'legacy') {
    return customRole ? [customRole._id] : [];
  }

  // for org roles, the ID is stored on the role object
  return [role.id];
}

function parseTeams(teams?: Team[]) {
  if (!teams) {
    return [];
  }

  return teams.map((t) => t.key);
}

export function toAPIPostBody(data: InviteMembersFormValues): PostMembersRequestBody {
  const emails = parseEmails(data.emails);
  const role = parseRole(data.role);
  const customRoles = parseCustomRoles(data.role, data.customRole);
  const teamKeys = parseTeams(data.teams).filter(Boolean) as string[];
  return emails.map((email) => ({
    email,
    role: role as PostMembersRequestBody[number]['role'],
    customRoles,
    teamKeys,
  }));
}
