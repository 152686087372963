import { pluralize } from '@gonfalon/strings';

type InviteAndSeatCountProps = {
  planHasUnlimitedSeats: boolean;
  numberOfNewSeatsToPurchase: number;
  numberOfNewSeatsLeft: number;
  is2021Plan: boolean;
  numberOfInvites: number;
};
export function InviteAndSeatCount({
  planHasUnlimitedSeats,
  numberOfNewSeatsToPurchase,
  numberOfNewSeatsLeft,
  is2021Plan,
  numberOfInvites,
}: InviteAndSeatCountProps) {
  const renderMicrocopy = () => {
    if (planHasUnlimitedSeats) {
      return;
    }

    if (numberOfNewSeatsToPurchase <= 0) {
      return `• ${numberOfNewSeatsLeft} more ${pluralize('seat', numberOfNewSeatsLeft)} available`;
    }

    if (is2021Plan) {
      return `• ${numberOfNewSeatsToPurchase} ${pluralize('seat', numberOfNewSeatsToPurchase)} needed`;
    }

    return (
      <span className="u-red" tabIndex={-1}>
        • Not enough seats!
      </span>
    );
  };

  return (
    <>
      Inviting {numberOfInvites} {pluralize('member', numberOfInvites)}
      {renderMicrocopy()}
    </>
  );
}
