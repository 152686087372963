import { Suspense, useEffect } from 'react';
import { Controller, Form, SubmitHandler, useForm } from 'react-hook-form';
import {
  getLimitPlanUpperLimit,
  getPlanTypeForLimits,
  getSeatAvailability,
  is2021Plan,
  isEnterprisePlan,
  isModernSubscription,
  LimitNames,
} from '@gonfalon/billing';
import { useAccountContext } from '@gonfalon/context';
import { isSeatLimitEnforced } from '@gonfalon/dogfood-flags';
import { RoleName, RoleSelect } from '@gonfalon/permissions';
import {
  getLimitPlansQuery,
  getSubscriptionUsageQuery,
  InviteMembersBatchError,
  isRESTAPIError,
  useInviteMembersBatch,
} from '@gonfalon/rest-api';
import { pluralize } from '@gonfalon/strings';
import { conditionalSuspenseQuery, disabledSuspenseQueryData } from '@gonfalon/suspense';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  ButtonGroup,
  FieldError,
  Label,
  Menu,
  MenuTrigger,
  Popover,
  ProgressBar,
  snackbarQueue,
  TextArea,
  TextField,
  toastQueue,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';
import { useSuspenseQueries } from '@tanstack/react-query';
import cx from 'clsx';
import { Box } from 'launchpad';

import InviteMembersSelectTeam from 'components/inviteMembers/InviteMembersSelectTeam';
import { UpgradeInlineBanner } from 'components/upsells/UpgradeInlineBanner/UpgradeInlineBanner';
import {
  trackCustomRoleSelected,
  trackInviteMembersModalCancelButtonClicked,
  trackInviteMembersTeamDropdownClicked,
} from 'utils/accountUtils';

import { InviteAndSeatCount } from './InviteAndSeatCount';
import { InviteMembersWarning } from './InviteMembersWarning';
import { LegacyRoleMenu } from './LegacyRoleMenu';
import { OrganizationRoleMenu } from './OrganizationRoleMenu';
import { RequiredAsterisk } from './RequiredAsterisk';
import { InviteMembersFormSchema, InviteMembersFormValues, parseEmails, RoleValue, toAPIPostBody } from './schema';
import { SeatsUpsells } from './SeatsUpsells';

import styles from './InviteMembersForm.module.css';

export type InviteMembersFormProps = { onCancel: () => void; defaultValues?: Partial<InviteMembersFormValues> };

export function InviteMembersForm({ onCancel, defaultValues = { emails: '' } }: InviteMembersFormProps) {
  const { subscription, isLegacySubscription } = useAccountContext();
  const enforceSeatLimit = isSeatLimitEnforced();
  const canFetchPlanData = enforceSeatLimit && !isLegacySubscription;
  const planType = getPlanTypeForLimits(subscription);
  const [subscriptionUsageRes, limitPlansRes] = useSuspenseQueries({
    queries: [
      conditionalSuspenseQuery({ query: getSubscriptionUsageQuery({}), enabled: canFetchPlanData }),
      conditionalSuspenseQuery({
        query: getLimitPlansQuery({ planType }),
        enabled: !isLegacySubscription && !isEnterprisePlan(subscription),
      }),
    ],
  });

  const subscriptionUsage = subscriptionUsageRes.data !== disabledSuspenseQueryData ? subscriptionUsageRes.data : {};
  const limitPlans = limitPlansRes.data !== disabledSuspenseQueryData ? (limitPlansRes.data.plans ?? {}) : {};
  const limitPlan = limitPlans[LimitNames.SEATS] ?? { name: 'Seats', tiers: [] };
  const planSeatLimit = getLimitPlanUpperLimit(limitPlan);

  const {
    mutate: inviteMembersPost,
    isPending,
    error: batchInviteError,
    isError: isBatchInviteError,
  } = useInviteMembersBatch();
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    formState: { isValid },
  } = useForm<InviteMembersFormValues>({
    resolver: zodResolver(InviteMembersFormSchema),
    defaultValues,
    mode: 'onChange',
  });

  const emailValue = watch('emails');
  const roleValue = watch('role');
  const customRoleValue = watch('customRole');
  const numberOfInvites = parseEmails(emailValue).length;

  const {
    planHasUnlimitedSeats,
    numberOfNewSeatsToPurchase,
    numberOfNewSeatsLeft,
    isPlanUpgradeNeeded,
    canRenderUpsell: canRenderAddSeatsUpsell,
    canRenderRequestSeatsUpsell,
  } = getSeatAvailability(subscription, numberOfInvites, subscriptionUsage, limitPlan);

  // todo - refactor getPricePerSeat from billingUtils into packages/billing
  // Not doing for phase 1 of OOTB roles release because it is not applicable to enterprise plans
  const costPerSeat = '-1';

  useEffect(() => {
    // ensure to clear customRole if role value is not custom
    if (customRoleValue && roleValue?.id !== RoleName.CUSTOM) {
      resetField('customRole');
    }
  }, [roleValue, customRoleValue, resetField]);

  const onSubmit: SubmitHandler<InviteMembersFormValues> = (data) => {
    if (canRenderAddSeatsUpsell && isModernSubscription(subscription)) {
      // Not doing this at this moment for phase 1 of OOTB roles release because it is for enterprise plans only,
      // and enterprise plans do not fall into this category of being able to make self serve changes.
      // Enterprise plans can only request seats.
      // See packages/billing/src/internal/modernSubscription/getSeatAvailability.ts for logic/more details

      // todo:
      // - create subscription change
      // - subscribeAndInvite
      // - previewSubscriptionPrice
      return;
    }

    const body = toAPIPostBody(data);

    inviteMembersPost(
      { body },
      {
        onSuccess: () => {
          toastQueue.add({ title: `Invited ${pluralize('member', body.length)}`, status: 'success' });
          onCancel();
        },
        onError: (err: unknown) => {
          snackbarQueue.add({
            description: isRESTAPIError(err) ? err?.message : 'Failed to invite members. Try again later.',
            status: 'error',
          });
        },
      },
    );
  };

  return (
    <Form control={control} onSubmit={handleSubmit(onSubmit)} data-test-id="form">
      {isPlanUpgradeNeeded && (
        <div className="u-mb-l">
          <UpgradeInlineBanner planSeatLimit={planSeatLimit} />
        </div>
      )}

      {isBatchInviteError && batchInviteError instanceof InviteMembersBatchError && (
        <InviteMembersWarning
          invalidEmails={batchInviteError.invalidEmails}
          rateLimitedEmails={batchInviteError.rateLimitedEmails}
        />
      )}

      <Box gap="$400" display="flex" flexDirection="column">
        <Controller
          control={control}
          name="emails"
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <TextField value={value} onChange={onChange} isInvalid={invalid} isRequired isDisabled={isPending}>
              <Label>
                Email addresses <RequiredAsterisk />
              </Label>
              <TextArea placeholder="Separate emails by comma or new line" />
              <FieldError>{error?.message}</FieldError>
              <p className="u-fs-sm u-tr u-mt-s u-pullright">
                <InviteAndSeatCount
                  numberOfInvites={numberOfInvites}
                  planHasUnlimitedSeats={planHasUnlimitedSeats}
                  numberOfNewSeatsToPurchase={numberOfNewSeatsToPurchase}
                  numberOfNewSeatsLeft={numberOfNewSeatsLeft}
                  is2021Plan={is2021Plan(subscription)}
                />
              </p>
            </TextField>
          )}
        />

        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <Box>
              <Label>
                Member role <RequiredAsterisk />
              </Label>
              <MenuTrigger>
                <Button className={cx(styles.roleButton, { [styles.invalid]: invalid })}>
                  {value ? value.name : 'Select Role'} <Icon name="chevron-down" size="small" />
                </Button>
                <Popover>
                  <Suspense
                    fallback={
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                      </Box>
                    }
                  >
                    <Menu>
                      <OrganizationRoleMenu isSubmenu onChange={onChange} />
                      <LegacyRoleMenu
                        isSubmenu
                        onChange={(role: RoleValue) => {
                          if (role.type === 'legacy' && role.id === RoleName.CUSTOM) {
                            trackCustomRoleSelected();
                          }
                          onChange(role);
                        }}
                      />
                    </Menu>
                  </Suspense>
                </Popover>
              </MenuTrigger>
            </Box>
          )}
        />

        {roleValue?.id === RoleName.CUSTOM && (
          <Controller
            control={control}
            name="customRole"
            render={({ field: { onChange, value } }) => (
              <Box>
                <Label>Custom role</Label>
                <RoleSelect onSelectionChange={onChange} role={value} />
              </Box>
            )}
          />
        )}

        <Controller
          control={control}
          name="teams"
          render={({ field: { onChange } }) => (
            <Box>
              {/* todo - rebuild without immutable, redux */}
              <InviteMembersSelectTeam
                onChangeSelectedTeams={(immutableTeams) => {
                  trackInviteMembersTeamDropdownClicked();
                  onChange(immutableTeams.map((t) => t.toJS()));
                }}
              />
            </Box>
          )}
        />
      </Box>

      <Box>
        <SeatsUpsells
          canRenderRequestSeatsUpsell={canRenderRequestSeatsUpsell}
          canRenderAddSeatsUpsell={canRenderAddSeatsUpsell}
          costPerSeat={costPerSeat}
          numberOfNewSeatsToPurchase={numberOfNewSeatsToPurchase}
        />
      </Box>

      <ButtonGroup className={styles.footer}>
        <Button
          variant="default"
          onPress={() => {
            trackInviteMembersModalCancelButtonClicked();
            onCancel();
          }}
          isDisabled={isPending}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" isDisabled={!isValid || isPending}>
          {`Invite ${pluralize('member', numberOfInvites)}`}
          {isPending && <ProgressBar size="small" isIndeterminate />}
        </Button>
      </ButtonGroup>
    </Form>
  );
}
