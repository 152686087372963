import { toBilling } from '@gonfalon/navigator';
import { LinkButton } from '@launchpad-ui/components';
import { Alert } from 'launchpad';

export type UpgradeInlineBannerProps = {
  planSeatLimit: number;
};

export const UpgradeInlineBanner = ({ planSeatLimit }: UpgradeInlineBannerProps) => (
  <Alert kind="error" header="No seats remaining">
    <p className="u-fs-md"> Your plan is limited to {planSeatLimit} members. To proceed, upgrade your plan.</p>
    <div className="u-mt-m">
      <LinkButton href={toBilling()}>Review billing</LinkButton>
    </div>
  </Alert>
);
