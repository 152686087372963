import { FormGroup, Label } from 'launchpad';

import { SelectTeamContainer } from 'components/SelectTeam/SelectTeamContainer';
import { Team } from 'utils/teamsUtils';

type InviteMembersSelectTeamProps = {
  defaultTeams?: Team[];
  onChangeSelectedTeams: (teams: Team[]) => void;
};

const InviteMembersSelectTeam = ({ defaultTeams, onChangeSelectedTeams }: InviteMembersSelectTeamProps) => (
  <FormGroup name="teams">
    <Label htmlFor="teams">Teams</Label>
    <SelectTeamContainer
      defaultTeams={defaultTeams}
      filterOutIDPSyncedTeams
      filterOutTeamsWithoutUpdateMemberPermissions
      id="teams"
      onChange={(teams: Team[]) => {
        onChangeSelectedTeams(teams);
      }}
    />
  </FormGroup>
);

/* eslint-disable import/no-default-export */
export default InviteMembersSelectTeam;
