import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelectProps } from '@gonfalon/launchpad-experimental';
import { List, Map } from 'immutable';

import { fetchTeams as fetchTeamsAction } from 'actions/teams';
import { SelectTeam } from 'components/SelectTeam/SelectTeam';
import { profileSelector } from 'reducers/profile';
import { teamsEntitiesSelector, teamsRequestSelector } from 'reducers/teams';
import { ready } from 'utils/reduxUtils';
import { createTeamsFilters, getTeamsWithNoUpdateMemberAccess, Team, TeamsFilters } from 'utils/teamsUtils';

const useRedux = (filterOutIDPSyncedTeams?: boolean, filterOutTeamsWithoutUpdateMemberPermissions?: boolean) => {
  const dispatch = useDispatch();
  const fetchTeams = (teamsFilters: TeamsFilters) => dispatch(fetchTeamsAction(teamsFilters));
  const profile = useSelector(profileSelector);
  let teams: Map<string, Team> = useSelector(teamsEntitiesSelector);
  if (filterOutIDPSyncedTeams) {
    teams = teams.filter((team) => !team._idpSynced);
  }
  if (filterOutTeamsWithoutUpdateMemberPermissions) {
    const profileEntity = profile.get('entity');
    const teamsWithoutAccess = getTeamsWithNoUpdateMemberAccess(teams.toList(), profileEntity);
    teams = teams.filter((team) => !teamsWithoutAccess.includes(team.key));
  }
  const teamsRequest = useSelector(teamsRequestSelector);
  const isReady = ready(teamsRequest);

  return {
    fetchTeams,
    isReady,
    teams,
  };
};

export type SelectTeamContainerProps = Omit<Partial<CustomSelectProps>, 'onChange'> & {
  customNoOptionsMessage?(teamCount: number): string;
  filterOutIDPSyncedTeams?: boolean;
  filterOutTeamsWithoutUpdateMemberPermissions?: boolean;
  defaultTeams?: Team[];
  filterTeams?: List<string>;
  id?: string;
  onChange(teams: Team[]): void;
};

export function SelectTeamContainer({
  customNoOptionsMessage,
  defaultTeams,
  filterOutIDPSyncedTeams,
  filterOutTeamsWithoutUpdateMemberPermissions,
  filterTeams,
  onChange,
  id,
}: SelectTeamContainerProps) {
  // On load, set default to defaultTeams
  const [selectedTeams, setSelectedTeams] = useState<Array<Team | undefined> | undefined>(defaultTeams);

  const { fetchTeams, isReady, teams } = useRedux(
    filterOutIDPSyncedTeams,
    filterOutTeamsWithoutUpdateMemberPermissions,
  );

  useEffect(() => {
    fetchTeams(createTeamsFilters({ limit: 1000 }));
  }, []);

  // On changes, set the selected teams back to what's in the dropdown
  const handleTeamSelectionChange = (newlySelectedTeams: Team[]) => {
    setSelectedTeams(newlySelectedTeams);
    onChange(newlySelectedTeams);
  };

  return (
    <SelectTeam
      customNoOptionsMessage={customNoOptionsMessage}
      filterTeams={filterTeams}
      id={id}
      isLoading={!isReady}
      onChange={handleTeamSelectionChange}
      selectedTeams={selectedTeams}
      teams={teams}
    />
  );
}
