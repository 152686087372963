import { lazy, useEffect } from 'react';
import { Alert } from 'launchpad';

import RequestSeatsControl from 'components/requestSeats/RequestSeatsControl';
import { getComponentAnalytics, trackUpsellFeatureViewed } from 'utils/analyticsUtils';

import styles from './styles/UpsellRequestSeatsBanner.module.css';

const AccountCircleImg = lazy(async () =>
  import(
    /* webpackChunkName: "AccountCircleImg" */ 'components/upsells/UpsellRequestSeatsBanner/images/AccountCircleImg'
  ).then((module) => ({
    default: module.AccountCircleImg,
  })),
);

export const UpsellRequestSeatsBanner = () => {
  useEffect(() => {
    trackUpsellFeatureViewed(
      'Request seats',
      getComponentAnalytics({
        component: 'UpsellRequestSeatsBanner',
        type: 'view',
      }),
    );
  }, []);

  return (
    <div className={styles.Upsell}>
      <AccountCircleImg className={styles.Icon} size="xlarge" withColor />
      <Alert header="Add seats to your Enterprise plan" noIcon isInline>
        <p>In order to proceed, you must contact your sales rep to request more seats.</p>
        <RequestSeatsControl analyticsProps={{ componentName: 'UpsellRequestSeatsBanner', type: 'button' }} />
      </Alert>
    </div>
  );
};
