import { Key } from 'react-aria-components';
import { formattedRoleName, roleDescription, RoleName, simpleRoles } from '@gonfalon/permissions';
import { Menu, MenuItem, Popover, SubmenuTrigger, Text } from '@launchpad-ui/components';

import { RoleValue } from './schema';

type LegacyRoleMenuProps = { isSubmenu?: boolean; onChange: (role: RoleValue) => void };

export function LegacyRoleMenu({ isSubmenu = false, onChange }: LegacyRoleMenuProps) {
  const menuItems: RoleValue[] = [...simpleRoles.filter((r) => r !== RoleName.OWNER), RoleName.CUSTOM].map((role) => ({
    type: 'legacy',
    id: role,
    name: formattedRoleName(role),
    description: roleDescription(role),
  }));

  const renderMenuItem = (item: RoleValue) => (
    <MenuItem>
      <Text slot="label">{item.name}</Text>
      <Text slot="description">{item.description}</Text>
    </MenuItem>
  );

  const handleAction = (id: Key) => {
    const item = menuItems.find((menuItem) => menuItem.id === id);
    if (!item) {
      return;
    }
    onChange(item);
  };

  if (isSubmenu) {
    return (
      <SubmenuTrigger>
        <MenuItem>
          <Text slot="label">Legacy roles</Text>
          <Text slot="description">
            Take advantage of our new role-based access control to provide invitees acccess to your organization's
            LaunchDarkly account.
          </Text>
        </MenuItem>
        <Popover>
          <Menu items={menuItems} onAction={handleAction}>
            {(item) => renderMenuItem(item)}
          </Menu>
        </Popover>
      </SubmenuTrigger>
    );
  }

  return (
    <Menu items={menuItems} onAction={handleAction} aria-label="Legacy roles">
      {(item) => renderMenuItem(item)}
    </Menu>
  );
}
