import { isArray } from '@gonfalon/es6-utils';
import { CustomSelect, OptionProps } from '@gonfalon/launchpad-experimental';
import { List, OrderedMap } from 'immutable';

import { stringContains } from 'utils/stringUtils';
import { sortTeams, Team } from 'utils/teamsUtils';

export type SelectTeamProps = {
  customNoOptionsMessage?(teamCount: number): string;
  disabled?: boolean;
  // team keys to filter out of the menu options
  filterTeams?: List<string>;
  id?: string;
  isLoading: boolean;
  onChange(teams?: Team[]): void;
  selectedTeams?: Array<Team | undefined>;
  teams: OrderedMap<string, Team>;
};

export function SelectTeam({
  customNoOptionsMessage,
  disabled,
  filterTeams,
  id,
  isLoading,
  onChange,
  selectedTeams,
  teams,
}: SelectTeamProps) {
  const noOptionsMessage = customNoOptionsMessage ? customNoOptionsMessage(teams.size) : 'No teams found';
  const options = isLoading
    ? []
    : teams
        .toList()
        .sort((a, b) => sortTeams(a, b))
        .map((team) => ({
          value: team.key,
          label: team.name,
          key: team.key,
        }));

  const selectedOptions = isLoading
    ? []
    : selectedTeams?.map((team) =>
        team?.key
          ? {
              value: team.key,
              label: team.name,
              key: team.key,
            }
          : undefined,
      );

  const filterOption = ({ data }: OptionProps, searchValue: string) =>
    !filterTeams?.includes(data.key) &&
    (stringContains(data.label, searchValue) || stringContains(data.key, searchValue));

  const handleChange = (option: OptionProps | null) => {
    if (isArray(option)) {
      const teamArray = option.map((data: OptionProps) => teams.get(data.value));
      onChange(teamArray as Team[]);
    }
  };

  const customStyles = {
    control: { padding: '0.125rem 0' },
    valueContainer: { maxHeight: '10.625rem', overflow: 'auto', padding: '0.125rem 0.1875rem' },
    menuList: { maxHeight: '200px' },
    multiValue: {
      alignItems: 'center',
      background: 'var(--lp-color-bg-interactive-secondary-hover)',
      borderRadius: '0.5rem',
      height: '1.75rem',
      maxWidth: '32.5rem',
      marginLeft: '0.1875rem',
      paddingLeft: '0.0625rem',
    },
    multiValueLabel: {},
    multiValueRemove: {
      borderRadius: '0 0.5rem 0.5rem 0',
      height: '100%',
      display: disabled ? 'none' : 'flex',
    },
  };
  return (
    <CustomSelect
      ariaLabel="Select teams"
      placeholder="Select teams"
      styles={customStyles}
      disabled={disabled}
      noOptionsMessage={() => (isLoading ? 'Loading' : noOptionsMessage)}
      value={selectedOptions}
      options={options}
      id={id}
      isClearable={false}
      isMulti
      onChange={handleChange}
      filterOption={filterOption}
    />
  );
}
