import { isEnterprisePlan } from '@gonfalon/billing';
import { useAccountContext } from '@gonfalon/context';
import { isApprovalByTeamEnabled, outOfTheBoxRolesReleasePhase } from '@gonfalon/dogfood-flags';

import { InviteMembersModal } from 'components/InviteMembersModal';

import { InviteMembersControl, InviteMembersControlProps } from './InviteMembersControl';

type InviteMembersOptionProps = {
  component: string;
  onClick?(): void;
  inviteMembersModalProps?: Pick<InviteMembersControlProps, 'modalProps'>['modalProps'];
};

export const InviteMembersOption = ({ component, onClick, inviteMembersModalProps }: InviteMembersOptionProps) => {
  const notFoundMessage = isApprovalByTeamEnabled() ? 'No members or teams found.' : 'No members found.';
  const { subscription } = useAccountContext();

  if (outOfTheBoxRolesReleasePhase() === 1 && isEnterprisePlan(subscription)) {
    return (
      <>
        {notFoundMessage}
        <br />
        <InviteMembersModal
          analyticsProps={{ componentName: component, type: 'link', referrer: component }}
          buttonProps={{
            variant: 'minimal',
            onPress: onClick,
          }}
        />
      </>
    );
  }

  return (
    <>
      {notFoundMessage}
      <br />
      <InviteMembersControl
        analyticsProps={{
          componentName: component,
          type: 'link',
        }}
        buttonProps={{ kind: 'link', onClick }}
        modalProps={{ referrer: component, ...inviteMembersModalProps }}
      />
    </>
  );
};
