import { SubmitButton } from 'components/ui/buttons';

import { UpsellAddSeatsBanner, UpsellAddSeatsBannerProps } from './UpsellAddSeatsBanner';

type InviteMembersUpsellAddSeatsBannerProps = Pick<
  UpsellAddSeatsBannerProps,
  'costPerSeat' | 'numberOfNewSeatsToPurchase'
>;
export const InviteMembersUpsellAddSeatsBanner = ({
  costPerSeat,
  numberOfNewSeatsToPurchase,
}: InviteMembersUpsellAddSeatsBannerProps) => (
  <UpsellAddSeatsBanner
    costPerSeat={costPerSeat}
    numberOfNewSeatsToPurchase={numberOfNewSeatsToPurchase}
    upsellFeature="invite_n_members"
    confirmPurchaseBtn={
      <SubmitButton loadingText="Subscribing and updating" size="small">
        Confirm purchase
      </SubmitButton>
    }
  />
);
