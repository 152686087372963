import { InviteMembersUpsellAddSeatsBanner } from 'components/upsells/UpsellAddSeatsBanner/InviteMembersUpsellAddSeatsBanner';
import { UpsellRequestSeatsBanner } from 'components/upsells/UpsellRequestSeatsBanner/UpsellRequestSeatsBanner';

type SeatsUpsellsProps = {
  canRenderRequestSeatsUpsell: boolean;
  canRenderAddSeatsUpsell: boolean;
  costPerSeat: string;
  numberOfNewSeatsToPurchase: number;
};

export function SeatsUpsells({
  canRenderRequestSeatsUpsell,
  canRenderAddSeatsUpsell,
  costPerSeat,
  numberOfNewSeatsToPurchase,
}: SeatsUpsellsProps) {
  if (canRenderRequestSeatsUpsell) {
    return (
      <div className="u-mt-l">
        <UpsellRequestSeatsBanner />
      </div>
    );
  }

  // add seats upsell
  if (canRenderAddSeatsUpsell) {
    return (
      <div className="u-mt-l">
        <InviteMembersUpsellAddSeatsBanner
          costPerSeat={costPerSeat}
          numberOfNewSeatsToPurchase={numberOfNewSeatsToPurchase}
        />
      </div>
    );
  }

  return null;
}
