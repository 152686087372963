import { Suspense, useEffect, useState } from 'react';
import { PressEvent } from 'react-aria';
import { enableSuggestInvites } from '@gonfalon/dogfood-flags';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogTrigger,
  Heading,
  IconButton,
  Modal,
  ModalOverlay,
  ProgressBar,
} from '@launchpad-ui/components';
import { Box } from 'launchpad';

import RequestSeatsControl from 'components/requestSeats/RequestSeatsControl';
import { useSuggestInviteMembersModal } from 'hooks/useSuggestInviteMembersModal';
import { trackAddMembersList, trackInviteMembersModalOpened } from 'utils/accountUtils';

import { InviteMembersForm } from './InviteMembersForm';
import { useCanInviteMembers } from './useCanInviteMembers';

type InviteMembersModalProps = {
  analyticsProps?: {
    componentName: string;
    type: 'button' | 'link';
    referrer: string;
  };
  buttonProps?: ButtonProps;
  openModalOnRender?: boolean;
};

export function InviteMembersModal({
  analyticsProps,
  buttonProps = {},
  openModalOnRender = false,
}: InviteMembersModalProps) {
  const { onPress } = buttonProps;
  const isSuggestInvitesEnabled = enableSuggestInvites();

  const [isOpen, setIsOpen] = useState(openModalOnRender);
  const { canInviteMembers, renderRequestSeats, shouldDisableButton } = useCanInviteMembers();

  // todo - rebuild this at some point
  const { openModal: openSuggestInvitesModal } = useSuggestInviteMembersModal();

  useEffect(() => {
    // todo - for non-enterprise, support the showBulkInviteMembers param (invite after purchase feature)
    trackInviteMembersModalOpened();
  }, []);

  const handleOpenChange = (nextIsOpen: boolean) => {
    if (isSuggestInvitesEnabled && !canInviteMembers) {
      openSuggestInvitesModal({ referrer: analyticsProps?.referrer ?? 'InviteMembersModal' });
      return;
    }

    setIsOpen(nextIsOpen);
  };

  const handleButtonPress = (e: PressEvent) => {
    trackAddMembersList({
      url: window.location.pathname,
      type: 'button',
      component: analyticsProps?.componentName ?? 'InviteMembersModal',
    });

    onPress?.(e);
  };

  if (!canInviteMembers && !isSuggestInvitesEnabled) {
    return null;
  }

  if (renderRequestSeats) {
    return (
      <RequestSeatsControl analyticsProps={{ componentName: 'InviteMembersModal' }} buttonProps={{ kind: 'primary' }} />
    );
  }

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
      <Button variant="primary" onPress={handleButtonPress} isDisabled={shouldDisableButton} {...buttonProps}>
        Invite members
      </Button>
      <ModalOverlay>
        <Modal>
          <Dialog>
            <div slot="header">
              <Heading slot="title">Invite members</Heading>
              <IconButton
                aria-label="close modal"
                icon="cancel"
                variant="minimal"
                onPress={() => handleOpenChange(false)}
              />
            </div>
            <div slot="body">
              <Suspense
                fallback={
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <ProgressBar aria-label="Loading…" isIndeterminate size="small" />
                  </Box>
                }
              >
                <InviteMembersForm onCancel={() => handleOpenChange(false)} />
              </Suspense>
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
