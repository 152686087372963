import { Snippet } from '@gonfalon/launchpad-experimental';
import { pluralize } from '@gonfalon/strings';
import { Alert, Heading, Text } from '@launchpad-ui/components';

type InviteMembersWarningProps = { invalidEmails?: string[]; rateLimitedEmails?: string[] };

export function InviteMembersWarning({ invalidEmails = [], rateLimitedEmails = [] }: InviteMembersWarningProps) {
  return (
    <>
      {rateLimitedEmails.length > 0 && (
        <Alert status="error">
          <>
            <Heading slot="title">{`${rateLimitedEmails.length} ${pluralize('invite', rateLimitedEmails.length)} failed to send`}</Heading>
            <Text slot="subtitle">
              You are being rate limited. Invite the following members later.
              <Snippet lang="text" withCopyButton>
                {rateLimitedEmails.join('\n')}
              </Snippet>
            </Text>
          </>
        </Alert>
      )}

      {invalidEmails.length > 0 && (
        <Alert status="error">
          <>
            <Heading slot="title">{`${invalidEmails.length} ${pluralize('invite', invalidEmails.length)} failed to send`}</Heading>
            <Text slot="subtitle">
              {invalidEmails.length === 1 ? 'This' : 'These'} {pluralize('email', invalidEmails.length)} already exist
              within LaunchDarkly. Try again with different emails.
              <Snippet lang="text" withCopyButton>
                {invalidEmails.join('\n')}
              </Snippet>
            </Text>
          </>
        </Alert>
      )}
    </>
  );
}
