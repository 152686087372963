import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router';
import { toBillingPlans } from '@gonfalon/navigator';
import { numberToWord, pluralize } from '@gonfalon/strings';
import { Alert, Button } from 'launchpad';

import {
  getComponentAnalytics,
  trackFeatureUpsells,
  trackUpsellCtaClicked,
  trackUpsellFeatureViewed,
} from 'utils/analyticsUtils';

import styles from './styles/UpsellAddSeatsBanner.module.css';

export type UpsellAddSeatsBannerProps = {
  costPerSeat: string;
  numberOfNewSeatsToPurchase: number;
  buttonCtaTextOverride?: string;
  upsellFeature: 'invite_n_members' | 'approve_join_request';
  actionText?: string;
  confirmPurchaseBtn: ReactNode;
};

export const UpsellAddSeatsBanner = ({
  costPerSeat,
  numberOfNewSeatsToPurchase,
  upsellFeature,
  buttonCtaTextOverride,
  confirmPurchaseBtn,
}: UpsellAddSeatsBannerProps) => {
  const [showConfirmPurchaseCTA, setShowConfirmPurchaseCTA] = useState(false);

  useEffect(() => {
    trackUpsellFeatureViewed(
      'Add seats',
      getComponentAnalytics({
        component: 'UpsellAddSeatsBanner',
        type: 'view',
      }),
    );
  }, []);

  const newSeatsToPurchaseInWords = numberToWord(numberOfNewSeatsToPurchase);
  const seatWord = pluralize('seat', numberOfNewSeatsToPurchase);
  const headerText = `Buy ${newSeatsToPurchaseInWords} ${seatWord} for ${costPerSeat} per seat per month`;

  const renderSubText = () => {
    const base = 'In order to proceed, you need to purchase';

    if (numberOfNewSeatsToPurchase > 1) {
      return `${base} additional ${seatWord}.`;
    }

    return `${base} an additional ${seatWord}.`;
  };

  const renderPurchaseSeatBtnText = () => {
    if (!!buttonCtaTextOverride) {
      return buttonCtaTextOverride;
    }

    switch (upsellFeature) {
      case 'invite_n_members':
        return `Buy ${seatWord} + Invite ${pluralize('member', numberOfNewSeatsToPurchase)}`;
      case 'approve_join_request':
      default:
        return `Buy ${seatWord}`;
    }
  };

  const handlePurchaseCtaClicked = () => {
    trackUpsellCtaClicked(
      'Add seats',
      renderPurchaseSeatBtnText(),
      getComponentAnalytics({
        component: 'UpsellAddSeatsBanner',
        type: 'button',
      }),
    );
    setShowConfirmPurchaseCTA(true);
  };

  const handleBillingLinkClicked = () => {
    trackFeatureUpsells('Learn about billing cycle', {
      cta: 'billing',
      type: 'link',
      component: 'UpsellAddSeatsBanner',
    });
  };

  return (
    <div>
      <Alert kind="error" header={headerText} className={styles.alert}>
        <p className="u-fs-md">{renderSubText()}</p>
        <div className="u-mt-m">
          {showConfirmPurchaseCTA ? (
            <>{confirmPurchaseBtn}</>
          ) : (
            <div className="u-mb-m u-flex u-flex-row u-flex-wrap u-flex-middle">
              <Button className={styles.button} onClick={handlePurchaseCtaClicked}>
                {renderPurchaseSeatBtnText()}
              </Button>
              <Link to={toBillingPlans()} className={styles.planLink} onClick={handleBillingLinkClicked}>
                Update my plan
              </Link>
            </div>
          )}
        </div>
      </Alert>
    </div>
  );
};
